import { RouteRecordRaw } from 'vue-router';
import { RouteMeta } from '@/shared/application/RouteMeta';
import { carrierRouter } from '@/page/infrastructure/router/carrierRouter';
import { metalPlanRouter } from '@/page/infrastructure/router/metalPlanRouter';
import HomeContent from '@/page/infrastructure/views/HomeContent.vue';
import FaqContent from '@/page/infrastructure/views/FaqContent.vue';
import EHealth from '@/page/infrastructure/views/EHealth.vue';

export const pageRouter: Array<RouteRecordRaw & { meta: RouteMeta }> = [
  {
    path: '/',
    name: 'home',
    meta: {
      layout: 'HomePage'
    },
    component: HomeContent
  },
  {
    path: '/faq',
    name: 'FAQ',
    meta: {
      layout: 'HomePage',
      title: 'Covered California FAQ - Frequently Asked Questions',
      description: 'Find FAQs below about Healthcare Reform and the Affordable Care Act. View questions and answers related to California Health Insurance, Covered',
    },
    component: FaqContent
  },
  {
    path: '/health-app-partnership',
    name: 'eHealthLead',
    meta: {
      layout: 'DefaultPage',
      title: 'Options Outside of California',
      description: 'We simplify what is normally a difficult process! Finding the right health plan outside California can be stressful and time-consuming. Let us help you find the best health insurance plan for you!',
      theme: 'light',
        quoteBanner: true,
        breadcrumbs: [
          {
            label: 'California Health Insurance',
            href: '/'
          },
          {
            label: 'Options Outside of California',
            href: '/health-app-partnership'
          }
        ],

    },
    component: EHealth
  },
  ...carrierRouter.getRoutes(),
  ...metalPlanRouter.getRoutes(),
];
