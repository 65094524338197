import { FooterItem, LinkItem } from '@/shared/domain/FooterItem';
import { FooterRepository } from '@/shared/domain/FooterRepository';
import FooterFeedback from '@/shared/infrastructure/components/footer/FooterFeedback.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import { ServicesApiResponse } from '@hfc/ui-core';

const { getBaseUrl, getEnrollmentAppUri } = UseUrlHelper();

export class FooterMemory implements FooterRepository {

  public async getFooterItems (): Promise<ServicesApiResponse<Array<FooterItem>>> {
    const footerItems = [
      {
        title: 'Covered California',
        href: getBaseUrl('/covered-california'),
        links: [
          {
            label: 'Get a Quote',
            href: getBaseUrl('/individual-and-family-quote'),
          },
          {
            label: 'Income Limits',
            href: getBaseUrl('/covered-california/income-limits'),
          },
          {
            label: 'Open Enrollment',
            href: getBaseUrl('/covered-california-enrollment/open-enrollment'),
          },
          {
            label: 'Deadlines',
            href: getBaseUrl('/covered-california/deadline'),
          },
          {
            label: 'Qualifying Life Events',
            href: getBaseUrl('/covered-california/life-events'),
          },
        ]
      },
      {
        title: 'Carriers',
        href: '',
        links: [
          {
            label: 'Anthem Blue Cross',
            href: getBaseUrl('/covered-california/health-insurance-companies/anthem'),
          },
          {
            label: 'Blue Shield',
            href: getBaseUrl('/covered-california/health-insurance-companies/blue-shield'),
          },
          {
            label: 'Kaiser Permanente',
            href: getBaseUrl('/covered-california/health-insurance-companies/kaiser'),
          },
          {
            label: 'Health Net',
            href: getBaseUrl('/covered-california/health-insurance-companies/health-net'),
          },
          {
            label: 'View All Carriers',
            href: getBaseUrl('/covered-california/health-insurance-companies'),
          },
        ]
      },
      {
        title: 'Metal Tiered Plans',
        href: getBaseUrl('/covered-california/plans'),
        links: [
          {
            label: 'Bronze',
            href: getBaseUrl('/covered-california/plans/bronze'),
          },
          {
            label: 'Silver',
            href: getBaseUrl('/covered-california/plans/silver'),
          },
          {
            label: 'Gold',
            href: getBaseUrl('/covered-california/plans/gold-80'),
          },
          {
            label: 'Platinum',
            href: getBaseUrl('/covered-california/plans/platinum-90'),
          },
          {
            label: 'Minimum Coverage',
            href: getBaseUrl('/covered-california/plans/minimum-coverage'),
          },
        ]
      },

      {
        title: 'Other Products',
        href: '',
        links: [
          {
            label: 'Dental',
            href: getEnrollmentAppUri('/dental/quote'),
          },
          {
            label: 'Vision',
            href: getEnrollmentAppUri('/vision/quote'),
          },
          {
            label: 'Medicare',
            href: getBaseUrl('/senior-health-insurance-options'),
          },
          {
            label: 'Medi-Cal',
            href: getBaseUrl('/covered-california/health-insurance-companies/medi-cal'),
          },
          {
            label: 'Short Term',
            href: getBaseUrl('/short-term-health-insurance'),
          },
          {
            label: 'Small Business',
            href: getBaseUrl('/group-health-insurance'),
          },
        ]
      },
    ];

    return {
      success: true,
      data: footerItems,
    }
  }
  public async getFooterExternalLinks (): Promise<ServicesApiResponse<Array<LinkItem>>> {
    const externalLinks = [
      {
        label: 'About Us',
        href: getBaseUrl('/about-us'),
      },
      {
        label: 'Contact Us',
        href: getBaseUrl('/contact-us'),
      },
      {
        label: 'Reviews',
        href: getBaseUrl('/reviews'),
      },
      {
        label: 'Privacy Policy',
        href: getBaseUrl('/privacy-policy'),
      },
      {
        label: 'Share Feedback',
        href: '#',
        customComponent: FooterFeedback,
        preventDefault: true,
      },
      {
        label: 'Site Map',
        href: getBaseUrl('/sitemap'),
      },
      {
        label: 'Blog',
        href: getBaseUrl('/blog'),
      },
    ];

    return {
      success: true,
      data: externalLinks,
    }
  }

}
