s<script setup lang="ts">
import { UseCarrierDataStore } from '@/page/infrastructure/composition/UseCarrierDataStore';
import CarrierPlan from '@/page/infrastructure/views/CarrierPlan.vue';
import ImageSection from '@/page/infrastructure/components/carrier/ImageSection.vue';
import TextAndImage from '@/page/infrastructure/components/TextAndImage.vue';
import { CarrierList, PrimaryButton } from '@hfc/ui-core';
import SectionInfo from '@/page/infrastructure/components/carrier/SectionInfo.vue';
import HashIcon from '@/page/infrastructure/components/carrier/HashIcon.vue';
import TaxIcon from '@/page/infrastructure/components/carrier/TaxIcon.vue';
import BriefcaseIcon from '@/page/infrastructure/components/carrier/BriefcaseIcon.vue';
import ShieldIcon from '@/page/infrastructure/components/carrier/ShieldIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getCdnUrl, getQuoteUrl } = UseUrlHelper();

const props = defineProps<{
  carrierId: CarrierList;
}>();

const networkBannerIcons = [
  { title: 'SOCIAL SECURITY NUMBERS', component: HashIcon },
  { title: 'FEDERAL TAX INFORMATION', component: TaxIcon },
  { title: 'EMPLOYMENT DETAILS', component: BriefcaseIcon },
  { title: 'OTHER HEALTH INSURANCE INFORMATION', component: ShieldIcon },
]

const { carrierData } = UseCarrierDataStore(props.carrierId);
</script>
<template>
  <CarrierPlan v-if="carrierData" :carrier-data="carrierData" :carrier-id="carrierId">
    <template v-slot:afterAboutInfo="{ pageData }">
      <div v-if="pageData.extraSection1" class="w-full py-16" :class="pageData.extraSection1.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="pageData.extraSection1.title"></h2>
          <ImageSection v-if="pageData.extraSection1.imageSection" class="mt-8" :content="pageData.extraSection1.imageSection">
            <template #content="{ content }">
              <div :class="[content.contentWidth, content.textDirection]">
                <h2 class="text-lg md:text-2xl lg:text-4xl" v-html="content.title"></h2>
                <div class="flex my-5 h-1 w-20 bg-teal-400 rounded-full"></div>
                <div class="w-full text-md md:text-xl lg:text-2xl" :class="content.textDirection ? content.textDirection : 'w-full text-center'" v-html="content.content"></div>
              </div>
            </template>
          </ImageSection>
          <div class="w-full" :class="pageData.extraSection1.textDirection ? pageData.extraSection1.textDirection : 'w-full text-center'" v-html="pageData.extraSection1.content"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterSectionInfo="{ pageData }">
      <SectionInfo v-if="pageData.extraSection2" :carrier-data="pageData.extraSection2" :carrier-id="carrierId"></SectionInfo>
    </template>
    <template v-slot:NetworkInfo="{ carrierData, carrierId }">
      <div v-if="carrierData" class="w-full py-16" :class="carrierData.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="carrierData.title"></h2>
          <div class="w-full" :class="carrierData.textDirection ? carrierData.textDirection : 'w-full text-center'" v-html="carrierData.content"></div>
          <ImageSection v-if="carrierData.imageSection" :content="carrierData.imageSection">
            <template #content="{ content }">
              <div class="text-left" :class="[content.contentWidth, content.textDirection]">
                <h2 class="text-lg md:text-2xl lg:text-4xl" v-html="content.title"></h2>
                <div class="flex flex-wrap w-full mt-4 lg:pt-0">
                  <div v-for="item in networkBannerIcons" class="flex flex-row w-full md:w-1/2 items-center py-0 md:py-4">
                    <div class="flex flex-row items-center"><component :is="item.component" class="w-8 sm:w-10 lg:w-16" /><span class="text-sm sm:text-md md:text-lg lg:text-xl ml-4 text-slate-500 font-semibold">{{ item.title }}</span></div>
                  </div>
                </div>
              </div>
            </template>
          </ImageSection>
          <div v-for="item in carrierData.subContent" class="flex flex-col w-full">
            <div class="text-blue-500 font-bold text-xl" v-html="item.title"></div>
            <p class="ml-5 mt-2" v-html="item.content"></p>
          </div>
          <div :class="carrierData.textDirection ? carrierData.textDirection : 'text-center'" v-html="carrierData.secondContent"></div>
        </div>
      </div>
    </template>
    <template v-slot:afterNetworkInfo="{ pageData }">
      <SectionInfo v-if="pageData.extraSection3" :carrier-data="pageData.extraSection3" :carrier-id="carrierId"></SectionInfo>
      <SectionInfo v-if="pageData.extraSection4" :carrier-data="pageData.extraSection4" :carrier-id="carrierId"></SectionInfo>
      <SectionInfo v-if="pageData.extraSection5" :carrier-data="pageData.extraSection5" :carrier-id="carrierId"></SectionInfo>
    </template>
    <template v-slot:afterFinalSection="{ pageData }">
      <div v-if="pageData.extraSection6" class="w-full py-16" :class="pageData.extraSection6.bgColor">
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6">
          <h2 class="mb-2 text-2xl lg:text-3xl" v-html="pageData.extraSection6.title"></h2>
          <div class="w-full" :class="pageData.extraSection6.textDirection ? pageData.extraSection6.textDirection : 'w-full text-center'" v-html="pageData.extraSection6.content"></div>
          <ImageSection v-if="pageData.extraSection6.imageSection" :content="pageData.extraSection6.imageSection">
            <template #content="{ content }">
              <div :class="[content.contentWidth, content.textDirection]">
                <h2 class="text-white text-lg md:text-2xl lg:text-4xl" v-html="content.title"></h2>
                <div class="flex my-5 h-1 w-20 bg-teal-400 rounded-full"></div>
                <div class="w-full text-white text-md md:text-xl lg:text-2xl" :class="content.textDirection ? content.textDirection : 'w-full text-center'" v-html="content.content"></div>
              </div>
            </template>
          </ImageSection>
          <div class="w-full" :class="pageData.extraSection6.textDirection ? pageData.extraSection6.textDirection : 'w-full text-center'" v-html="pageData.extraSection6.secondContent"></div>
          <div v-if="pageData.extraSection6.redirectUrl" class="flex mt-10">
            <PrimaryButton class="text-sm lg:text-md" size="lg" :href="pageData.extraSection6.redirectUrl">{{ pageData.extraSection6.redirectButtonText }}</PrimaryButton>
          </div>
        </div>
      </div>
      <SectionInfo v-if="pageData.extraSection7" :carrier-data="pageData.extraSection7" :carrier-id="carrierId"></SectionInfo>
      <SectionInfo v-if="pageData.extraSection8" :carrier-data="pageData.extraSection8" :carrier-id="carrierId"></SectionInfo>
      <div v-if="pageData.extraSection9" class="w-full" :class="pageData.extraSection9.bgColor">
        <TextAndImage div-class="px-6" :content-direction="pageData.extraSection9.contentDirection" :text-class="!pageData.extraSection9.imageUrl ? 'w-full' : 'flex flex-col lg:w-3/5'">
          <template #text>
            <div class="flex flex-col h-full items-center justify-center mt-10 lg:mt-0">
              <h2 class="mb-6" v-html="pageData.extraSection9.title"></h2>
              <div :class="pageData.extraSection9.textDirection ? pageData.extraSection9.textDirection : 'text-center'" v-html="pageData.extraSection9.content"></div>
              <PrimaryButton v-if="pageData.extraSection9.redirectUrl" class="text-sm mt-6 lg:text-md uppercase" size="lg" :href="getQuoteUrl()">{{ pageData.extraSection9.redirectButtonText }}</PrimaryButton>
            </div>
          </template>
          <template #image v-if="pageData.extraSection9.imageUrl">
            <div class="flex flex-1 justify-center">
              <img class="flex max-h-96 rounded" :src="getCdnUrl(pageData.extraSection9.imageUrl)">
            </div>
          </template>
        </TextAndImage>
        <div class="flex flex-1 flex-col items-center max-w-7xl mx-auto relative space-y-6 px-6 pb-16">
          <ImageSection v-if="pageData.extraSection9.imageSection" class="mt-6" :content="pageData.extraSection9.imageSection"></ImageSection>
        </div>
      </div>
    </template>
  </CarrierPlan>
</template>