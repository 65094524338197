import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import { title } from 'process';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const sharpPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Sharp Health Insurance Coverage`,
    content: `Find the Lowest Prices Available on Sharp Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Sharp',
    content: `<p>Since 1992 Sharp Health Plan has been serving the Southern Riverside and San Diego counties as a local not-for-profit organization and subsidiary of Sharp HealthCare. Offering an integrated health care delivery system, they are the leading provider of comprehensive health care in San Diego.</p><br>
    <p>In their own words they have an “Unwavering commitment to excellence and passion for caring…It’s our care philosophy-treating people, not patients, and transforming the health care experience for our entire community.”</p><br>
    <p>Sharp is one of 11 carriers offered in the California State Exchange and one of 6 carriers being offered in Covered California’s pricing region 19.</p>`
  },
  sectionInfo: {
    visible: true,
    title: 'Take Note',
    content: `<p>The NCQA (<a class="underline" href="https://www.ncqa.org/ReportCards/HealthPlans/HealthInsurancePlanRatings/NCQAHealthInsurancePlanRatings2015.aspx" target="blank">National Committee for Quality Assurance</a>) awarded Sharp the Wellness & Health Promotion Accreditation. They were the only health plan in California to be given this accreditation, and one of 8 health plans selected in the United States.</p><br>
    <a class="underline" href="https://www.sharphealthplan.com/wellness/best-health-wellness-program" target="blank">Best Health</a>, Sharp’s integrative wellness program offers online workshops, a health library of online video’s and information, fitness tracking tools and one-on-one health coaching.</p>`,
    imageUrl: '/assets/img/carriers/old-woman-helmet.webp'
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>PMG's are Shard's "Plan Medical Groups" from which you choose your doctor. Its is from these groups that you receive your specialty, hospital and urgent care services affiliated with your PMG.</p>`,
    imageSection: {
      title: `For individual and families purchasing a health plan through Covered California there are 2 networks available:`,
      imageUrl: '/assets/img/carriers/sharp-network-background.webp',
      bgColor: 'bg-blue-500',
      redirectButtonUrl: getQuoteUrl(),
      redirectButtonText: 'Get A Free Quote',
      multipleBoxContent: [
        {
          title: '1. Premier Network',
          boxData: [
            {
              title: '1,300+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Doctors',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '10',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Hospitals',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '2',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Medical Groups',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '25+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Urgent Care Centers',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '450+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Pharmacies',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            }
          ]
        },
        {
          title: '2. Performance Network',
          boxData: [
            {
              title: '2,200+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Doctors',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '13',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Hospitals',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '7',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Medical Groups',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '40+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Urgent Care Centers',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            },
            {
              title: '450+',
              titleColor: 'text-sky-500',
              titleSize: 'text-3xl',
              content: 'Pharmacies',
              boxColor: 'bg-white',
              boxHeight: 'h-32',
            }
          ]
        },
      ],
    },
    secondContent: `<p>For individual and families purchasing a health plan through Covered California there are 2 networks available:</p><br>
    <div class="flex flex-row w-full text-center">
      <div class="flex flex-1 justify-center"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Premier Network</div>
      <div class="flex flex-1 justify-center"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Performance Network</div>
    </div><br>
    <p>Sharp Health Plan has several thousand affiliated doctors including <a href="https://www.sharphealthplan.com/search?section=findacarelocation" target="_blank">12 local hospitals</a>.</p><br>
    <p>To view a list of doctor's please click here.</p>`
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Through Sharp’s Rees-Stealy Medical Group, select physicians can be accessed using <a class="underline" href="https://www.sharp.com/rees-stealy/telehealth-visits-with-your-doctor.cfm" target="_blank">Telehealth</a> for over the phone follow-up appointments and for certain medical conditions. They also offer <a class="underline" href="https://www.sharp.com/rees-stealy/nurse-connection.cfm" target="_blank">Sharp Nurse Connection</a> as a way for members to access medical advice after hours over the phone.</p><br>
    <p>If a doctor participates, members can use <a class="underline" href="https://www.sharp.com/patient/followmyhealth/" target="_blank">FollowMyHealth</a>, a secure online service which allows members to view test results, send and receive messages from their doctor, view personal health information, and manage appointments, etc.</p>`,
    imageUrl: '/assets/img/carriers/video-consulting-doctor.webp'
  },
  extraSection1: {
    visible: true,
    imageSection: {
      title: 'Contact <b>Health for California</b> to Learn More About the <b>Sharp</b> Plan',
      contentWidth: 'w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/sharp-banner.webp',
      redirectButtonUrl: getBaseUrl('/contact-us'),
      redirectButtonText: 'Contact Us'
    },
  },
};
