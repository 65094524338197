<script lang="ts" setup>
import { inject, onMounted, ref, provide } from 'vue';
import moment from 'moment';
import { LinkButton } from '@hfc/ui-core';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';
import { AppStore } from '@/shared/infrastructure/store/AppStore';
import { FooterItem, LinkItem } from '@/shared/domain/FooterItem';

let appStore = inject<AppStore>('AppStore') as AppStore;
if(!appStore) {
  appStore = new AppStore();
  provide('AppStore', appStore);
}

const { getCdnUrl } = UseUrlHelper();

const currentYear = moment().year();
let extraLinks = ref([] as Array<LinkItem>);
let footerItems = ref([] as Array<FooterItem>);

const handleLinkClick = (e: Event, link: LinkItem) => {
  if (link.preventDefault) {
    e.preventDefault();
  }
};

onMounted(async () => {
  const footerExtraLinksResponse = await appStore.getFooterExtraLinks();
  if(footerExtraLinksResponse.success && footerExtraLinksResponse.data) {
    extraLinks.value = footerExtraLinksResponse.data;
  }

  const footerItemsResponse = await appStore.getFooterItems();
  if(footerItemsResponse.success && footerItemsResponse.data) {
    footerItems.value = footerItemsResponse.data;
  }
})

</script>
<template>
  <footer class="bg-white h-64 flex flex-col leading-6">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 py-6 flex flex-col lg:flex-row w-full flex-1">
      <div class="w-full lg:w-1/4 pb-6 lg:pb-0">
        <a href="/" class="px-6 block lg:inline-block">
          <img :src="getCdnUrl('/assets/img/logos/hfc.png')" alt="Health For California"
            class="mx-auto lg:mx-0 w-[243px] h-[38px]" />
        </a>
        <div class="pt-3 lg:pt-0 columns-2 max-w-[200px] mx-auto md:max-w-auto lg:ml-7">
          <a href="https://www.facebook.com/HealthForCA" target="_blank" aria-label="Facebook."
            rel="noopener noreferrer" class="block mb-2 mt-4 sm:mt-0">
            <span class="flex justify-start w-full">
              <hfc-icon :icon="['fab', 'facebook']" class="text-4xl text-blue-500" />
            </span>
          </a>
          <LinkButton v-for="link in extraLinks" :key="link.label" :href="link.href" class="py-1 mb-1 md:py-0 inline-block whitespace-nowrap" @click="(e) => handleLinkClick(e, link)">
            <component v-if="link.customComponent" :is="link.customComponent"></component>
            <template v-else>{{ link.label }}</template>
          </LinkButton>
        </div>
      </div>
      <div class="w-full lg:w-3/4 flex flex-wrap lg:flex-nowrap text-center lg:text-left xg:px-5">
        <div class="w-1/2 lg:flex-grow pb-6 xl:pb-0" v-for="(footerItem, i) in footerItems" :key="i">
          <ul>
            <li class="text-gray-500 font-medium text-base mb-2">
              <LinkButton v-if="footerItem.href" custom-class="text-gray-500" :href="footerItem.href">
                {{ footerItem.title }}
              </LinkButton>
              <p v-else class="cursor-default">{{ footerItem.title }}</p>
            </li>
            <li v-for="link in footerItem.links" :key="link.label" class="py-1 md:py-0">
              <LinkButton :href="link.href">
                {{ link.label }}
              </LinkButton>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="text-center bg-gray-200 px-5">
      <p class="py-2 text-gray-600">
        Covered California is a registered trademark of the state of California | © {{ currentYear }}
        Health for California Insurance Center</p>
    </div>
  </footer>
</template>
