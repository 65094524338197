import { CarrierPage } from '@/page/domain/CarrierPage';
import DeskDoctorIcon from '@/page/infrastructure/components/carrier/DeskDoctorIcon.vue';
import PhoneDoctorIcon from '@/page/infrastructure/components/carrier/PhoneDoctorIcon.vue';
import SecondClipboardIcon from '@/page/infrastructure/components/carrier/SecondClipboardIcon.vue';
import SecondDoctorIcon from '@/page/infrastructure/components/carrier/SecondDoctorIcon.vue';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const healthNetPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Health Net Health <br>
    Insurance Coverage`,
    content: `Find the Lowest Prices Available on Health Net Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Health Net',
    content: `<p>Access to affordable and comprehensive health insurance is crucial, considering the economic landscape. Health Net stands out as a prominent provider in California, offering a variety of plans designed to meet diverse needs. Since its establishment, the networks and membership have grown exponentially, making it one of the largest in the state. This guide discusses Health Net insurance, including the available plans, enrollment process, packages and more.</p>`,
    textDirection: 'text-left',
  },
  sectionInfo: {
    visible: true,
    title: 'What Is Health Net Insurance?',
    content: `<p>Health Net insurance is a health care coverage provided by Health Net. Health Net is a <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us.html" target="_blank">managed care organization that offers</a> health insurance and related services to individuals and families in the United States. It was founded in 1979 in California and has grown in membership and networks. Health Net believes everyone deserves quality health care regardless of race, employment status, income, age or current health status. The organization is dedicated to transforming the health of the community, one person at a time.</p><br>
    <p>The goal is to keep Californians healthy by focusing on preventive care, identifying members needs and reducing health disparities. Programs like <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/population-health-management/start-smart.html" target="_blank">Start Smart for Your Baby®</a> and <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/health-and-wellness/diabetes-resources.html" target="_blank">diabetes management resources</a> allow members to take control of every aspect of their health in pursuit of holistic wellness.</p>`,
    imageUrl: '/assets/img/carriers/elder-woman-and-doctor-clipboard.webp',
    textDirection: 'text-left',
  },
  networkInfo: {
    visible: true,
    title: 'Health Net Membership and Networks Growth',
    content: `<p>With millions of members in California alone, Health Net's network offers something for everyone. Health Net Covered California plans are available throughout California.</p><br>
    <p>As of 2015. Health Net Covered California enrollees can access <a class="underline" href="https://www.uclahealth.org/" target="_blank">University of California, Los Angeles (UCLA)</a> hospitals and doctors by selecting the Small Group Health Net PPO on all metal tiers, including Bronze, Silver, Gold and Platinum. In 2018, Health Net brought its PPO into Sacramento, Placer, Yolo, Los Angeles, San Diego and parts of Southern California. It is one of the largest networks in the state today.</p><br>
    <p>Health Net's EnhancedCare PPO gives members the flexibility they expect from a PPO without all the cost. It comes with a smaller PPO network at a lower cost but still includes access to an advice nurse, virtual doctor, retail clinic and the Enhanced Care hospitals and doctors in-network. Members can go out of network at a higher cost. putting them in the driver's seat.</p><br>
    <p>To learn more, <a class="underline" href="https://www.healthnet.com/portal/providerSearch.action" target="_blank">preview Health Net's provider network</a>.</p>`,
    imageUrl: '/assets/img/carriers/elder-woman-and-doctor.webp',
    bgColor: 'bg-sky-50',
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'The Future is Here',
    content: `<p>Licensed nurses are available to Health Net members 24/7 to assist with many concerns, including minor illnesses and injuries, advice on urgent health situations and other health questions. Members also have access to online tools that allow them to see details about their plan and pharmacy benefits, view health screenings, change a doctor, use programs for weight management and more. Health Net offers a variety of health education classes in English and many in Spanish.</p>`,
    imageUrl: '/assets/img/carriers/man-and-doctor.webp',
    bgColor: 'bg-sky-50',
  },
  extraSection1: {
    visible: true,
    title: 'Types of Plans Under Health Net Insurance',
    content: `Health Net offers the following plans:`,
    imageSection: {
      title: 'Health Coverage Programs',
      titleSeparator: true,
      titleSeparatorColor: 'bg-teal-400',
      contentDirection: 'center',
      imageUrl: '/assets/img/carriers/health-net-background.webp',
      redirectButtonText: 'Get A Free Quote',
      boxContent: [{
        title: 'Health Maintenance Organization (HMO)',
        content: 'An HMO is a managed care health plan that usually requires members to receive covered care from doctors within the HMO.',
        boxData: {
          component: SecondDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Preferred Provider Organization (PPO)',
        content: 'A PPO is a managed care health plan involving a network of medical professionals.',
        boxData: {
          component: DeskDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Exclusive Provider Organization (EPO)',
        content: 'An EPO is a type of health insurance plan that combines the features of a PPOs and HMOs.',
        boxData: {
          component: SecondClipboardIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Health Care Service Plan (HSP)',
        content: 'For people who prefer to visit physicians or health care professionals without first obtaining a referral.',
        boxData: {
          component: PhoneDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      }]
    },
    subContent: [{
        title: 'Health Maintenance Organization (HMO)',
        content: `<p>An HMO is a <a class="underline" href="https://www.healthcare.gov/glossary/health-maintenance-organization-hmo/" target="_blank">managed care health plan</a> that usually requires members to receive covered care from doctors within the HMO. It does not cover out-of-network expenses except for emergencies and may require members to work or live in its service area. In most cases, members must choose a primary care physician (PCP) and need a referral to see a specialist or obtain certain services.</p><br>
        <p>HMO plans focus on preventive care and wellness and provide integrated care. Members often get coverage for routine checkups, vaccinations and screenings at no additional cost. Premiums and out-of-pocket costs are usually lower than plans like PPOs. Members may pay copayments for visits and services but often as no deductibles </p>`
      },
      {
        title: 'Preferred Provider Organization (PPO)',
        content: `<p>APPO is a <a class="underline" href="https://www.healthcare.gov/glossary/preferred-provider-organization-ppo/" target="_blank">managed care health plan</a> involving a network of medical professionals. Members can choose their preferred physician and visit specialists without referrals. There is no requirement to select a PCP. Members may also receive care from out-of-network providers but at a higher cost. PPOs usually have higher premiums but with added flexibility. Members must pay a deductible before coverage insurance kicks in, along with copayments and coinsurance for services.</p>`
      },
      {
        title: 'Exclusive Provider Organization (EPO)',
        content: `<p>An EPO is a <a class="underline" href="https://www.healthcare.gov/glossary/exclusive-provider-organization-epo-plan/" target="_blank">type of health insurance plan</a> that combines the features of PPOs and HMOs. It requires members to use a specific network of doctors and hospitals for their health care needs and does not cover out-of-network care except for emergencies. Typically, members do not need a referral from a PCP to see a specialist, making it simpler to access care.</p><br>
        <p>EPOs have relatively lower premiums, but members pay for copayments and coinsurance for services received within the network. Generally, a deductible must be met before certain benefits kick in. The plan is ideal for individuals and families willing to use a limited network of providers.</p>`
      },
      {
        title: 'Health Care Service Plan (HSP)',
        content: `<p>HSPs are designed for people who prefer to visit physicians or health care professionals without first obtaining a referral. Members must choose a PCP from the network but can see any participating provider within the network at any time. There is a requirement to pay a deductible before the benefits kick in, and after that, the member pays copayments until they satisfy the out-of-pocket maximum.</p>`
      }
    ],
  },
  extraSection2: {
    visible: true,
    title: 'The Health Net Medical Insurance Enrollment Process',
    content: `Enrolling for Health Net insurance coverage is straightforward and involves the following steps:`,
    imageSection: {
      title: 'The <span class="text-yellow">Health Net</span> <br>Medical Insurance<br> Enrollment Process',
      titleSeparator: true,
      titleSeparatorColor: 'bg-teal-400',
      contentDirection: 'center',
      imageUrl: '/assets/img/carriers/health-net-banner.webp',
      redirectButtonText: 'Get A Free Quote',
      boxContent: [{
        title: 'Health Maintenance Organization (HMO)',
        content: 'An HMO is a managed care health plan that usually requires members to receive covered care from doctors within the HMO.',
        boxData: {
          component: SecondDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Preferred Provider Organization (PPO)',
        content: 'A PPO is a managed care health plan involving a network of medical professionals.',
        boxData: {
          component: DeskDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Exckusive Provider Organization (EPO)',
        content: 'An EPO is a type of health insurance plan that combines the features of a PPOs and HMOs.',
        boxData: {
          component: SecondClipboardIcon,
          boxColor: 'bg-slate-100'
        }
      },
      {
        title: 'Health Care Service Plan (HSP)',
        content: 'For people who prefer to visit physicians or health care professionals without first obtaining a referral.',
        boxData: {
          component: PhoneDoctorIcon,
          boxColor: 'bg-slate-100'
        }
      }],
      subContent: [{
          title: `Determine eligibility`
        },
        {
          title: `Explore plan options`
        },
        {
          title: `Gather the necessary information`
        },
        {
          title: `Apply for coverage`
        },
        {
          title: `Pay insurance premiums`
        },
        {
          title: `Receive insurance documents`
        },
      ],
    },
    subContent: [{
        content: `<p><span class="font-bold">Determine eligibility:</span> Assess your health needs, budget and eligibility for a Health Net plan. Note the open or special enrollment periods (SEPs) applicable to your situation.</p>`
      },
      {
        content: `<p><span class="font-bold">Explore plan options:</span> Research the plans in your area. Compare the different options to determine which is ideal.</p>`
      },
      {
        content: `<p><span class="font-bold">Gather the necessary information:</span> Gather information such as social security number and income details for each enrolling individual. You may also need details about current or previous health coverage.</p>`
      },
      {
        content: `<p><span class="font-bold">Apply for coverage:</span> Complete the application online or in person. Ensure the information you submit is accurate, and confirm whether the application was successful.</p>`
      },
      {
        content: `<p><span class="font-bold">Pay insurance premiums:</span> Once enrolled, pay your first premium to activate your coverage. Health Net will provide information on payment methods and billing cycles.</p>`
      },
      {
        content: `<p><span class="font-bold">Receive insurance documents:</span> After processing your application and payment, you will receive your health insurance card, a summary of benefits and coverage, and policy details outlining what your plan covers. These materials will help you utilize the plan.</p>`
      },
    ],
    secondContent: `You can contact Health for California to guide you throughout the process. We can help you find suitable coverage and enroll for free. <a class="underline" href="${getBaseUrl('/contact-us')}">Contact us today</a>.`,
  },
  extraSection3: {
    visible: true,
    title: 'Types of Plans Under Health Net Insurance',
    content: `Health Net offers the following plans:`,
    subContent: [{
        title: '2024',
        list: [
          `Named among <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2024-07-18-hn-newsweek-named-health-net-among-americas-greatest-workplaces-lgbtq-2024.html" target="_blank">America's Greatest Workplaces for LGBTQ+</a> by Newsweek`
        ]
      },
      {
        title: '2023',
        list: [
          `Ranked number one <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2022-10-10-health-net-named-by-newsweek-top-provider-customer-service-second-year-in-a-row.html" target="_blank">among the top health care insurers</a> in Newsweek's "America's Best Customer Service" list`,
          `Named among <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2022-12-19-newsweek-names-health-net-among-americas-greatest-workplaces-2023-for-diversity.html" target="_blank">America's Greatest Workplaces 2023</a> for Diversity by Newsweek`
        ]
      },
      {
        title: '2022',
        list: [
          `Received the <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2022-09-29-health-net-awarded-health-equity-accreditation-plus-by-ncqa.html" target="_blank">Healthy Equity Accreditation Plus award</a> from the prestigious National Committee for Quality Assurance (NCQA)`,
          `Ranked number one <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2022-10-10-health-net-named-by-newsweek-top-provider-customer-service-second-year-in-a-row.html" target="_blank">among the top health care insurers</a> in Newsweek's "America's Best Customer Service" list`
        ]
      },
      {
        title: '2021',
        list: [
          `Named the <a class="underline" href="https://www.businesswire.com/news/home/20210713005948/en/Health-Net-Ranks-Highest-In-Customer-Service-Among-Health-Insurers" target="_blank">number one in customer service</a> among health insurers by Forrester's proprietary 2021 U.S. Customer Experience Index survey`,
          `Named <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2021-09-02-health-net-named-by-newsweek-to-annual-americas-best-customer-service-ranking.html" target="_blank">America's best customer service</a> among health insurance plans by Newsweek`,
          `Received the California Department of Health Care Services' <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2021-12-15-health-net-earns-customer-satisfaction-award-from-california-department-of-health-care-services.html" target="_blank">Consumer Satisfaction Award 2021</a> - Large Scale Plan (Child)`
        ]
      },
      {
        title: '2020',
        list: [
          `Recognized for <a class="underline" href="https://www.healthnet.com/content/healthnet/en_us/news-center/news-releases/2020-11-09-Health-Net-Star-Quality-Ratings-Report.html" target="_blank">excellent quality of care and customer service</a> by the Center for Medicare & Medicaid Services`
        ]
      },
      {
        title: '2019',
        list: [
          `Received the Multicultural Health Care Distinction Award from the NCQA`,
          `Received the Health Equity Award for Postpartum Care Disparities Project from The Department of Health Care Services (DHCS)`,
          `Received the <a class="underline" href="https://www.healthnet.com/portal/home/content/iwc/home/articles/newsroom/photo-network-health-net-recog.action" target="_blank">Diversity Champion Award</a> from the National Association of Asian Pacifics in Politics and Public Affairs`,
          `Received the <a class="underline" href="https://www.nationalalliancehealth.org/www/news/news-press-releases/2019-evalue8-awards"target="_blank">eValue8 Innovation Award</a> from the National Alliance of Healthcare Purchaser Coalitions`,
        ]
      },
    ],
  },
  extraSection4: {
    visible: true,
    title: 'Ambetter For Health Net',
    content: `<p>In 2022, Health Net's individual and family plan (FP) was renamed Ambetter from Health Net. The Ambetter name helps Health Net draw from the national strength of Centene, their parent company, with new programs and services designed to help members achieve better health results.</p><br>
    Life is uncertain, but with the right health care package, you can enjoy greater peace of mind for whatever life throws your way. Health coverage gives you experienced, adequate health care to fall back on when you need it most. Ambetter from Health Net is the customizable answer to your health care needs.</p><br>
    To help you choose the right package, here's a description of each Ambetter from Health Net insurance plan:</p>`,
    subContent: [{
        title: 'Bronze 60 Plan',
        content: `<p>As one of the most affordable options available, this plan is perfect if you want to make small monthly payments while still receiving coverage for a few checkups and preventive care. We understand you only want to pay for what you need. If you're a young person with no dependents, this plan could be the answer you've been looking for. Remember that you'll have to pay out of pocket to see a specialist.</p>`
      },
      {
        title: 'Silver 70 Plan',
        content: `<p>After choosing the Silver Plan, you'll make higher monthly payments than the Bronze Plan but receive more coverage benefits in return. The Silver Plan covers all the basics you could need and more for health care in California. Although, you will have to pay more when visiting the hospital or receiving outpatient surgery. Choose this plan if you're the type of person who works hard to ensure you always have the health care you need.</p>`
      },
      {
        title: 'Gold 80 Plan',
        content: `<p>After choosing the Gold Plan, you'll pay modest monthly payments and get excellent, comprehensive health care coverage. You'll enjoy low out-of-pocket costs when visiting the hospital or seeing a specialist. The lower deductible you'll have to pay when health needs arise will allow you to save more money in the long run. And, of course, saving more means having a bigger budget and more peace of mind for the future.</p>`
      },
      {
        title: 'Platinum 90 Plan',
        content: `<p>The Platinum Plan requires higher monthly payments, but the result is the highest level of care you can receive from Health Net. The benefits of this plan include handling emergencies, receiving preventive care and visiting the physician, all without having to pay a deductible. If you want to avoid out-of-pocket expenses for yourself or your family, this plan is for you.</p>`
      },
    ],
  },
  extraSection5: {
    visible: true,
    title: 'Get a Quote For Health Net Coverage',
    imageSection: {
      title: 'Get a Quote For Health Net Coverage',
      titleSeparator: true,
      titleSeparatorColor: 'bg-teal-400',
      contentDirection: 'center',
      imageUrl: '/assets/img/carriers/health-net-doctor-banner.webp',
      redirectButtonText: 'GET IN TOUCH',
      redirectButtonUrl: getQuoteUrl(),
      contentWidth: 'w-1/2',
    },
    secondContent: `<p><a class="underline" href="${getQuoteUrl()}">Request a quote</a> for Ambetter from Health Net health insurance today to take the next step toward obtaining high- quality health care for you and your loved ones. Health for California is committed to helping individuals and families find the best coverage without any charge. We leverage an advanced digital platform to provide fast and accurate results. We are always available to help and answer your questions.</p>`,
    bgColor: 'bg-slate-100',
  },
};
