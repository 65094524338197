import { CarrierPage } from '@/page/domain/CarrierPage';
import { UseUrlHelper } from '@/page/infrastructure/composition/UseUrlHelper';

const { getBaseUrl, getQuoteUrl } = UseUrlHelper();

export const anthemPageData: Partial <CarrierPage> = {
  logoSection: {
    visible: true,
    title: `Anthem Blue Cross <br> Health Insurance Coverage`,
    content: `Find the Lowest Prices Available on Anthem Blue Cross Medical Insurance Plans or Get Discounts Through Our Covered California Options`
  },
  aboutInfo: {
    visible: true,
    title: 'About Anthem Blue Cross',
    content: `<p>Anthem Blue Cross health insurance offers comprehensive coverage to qualifying families and individuals. Anthem Blue Cross is committed to helping its members effectively manage their health care needs while staying healthy. Various plans are available, including options for emergency services, preventive care and prescription medications. Members have peace of mind, knowing they receive quality care from a network of doctors and hospitals.</p>`,
  },
  sectionInfo: {
    visible: true,
    title: 'Understanding Anthem Blue Cross California',
    content: `<p>Founded in 1936. Anthem has grown from a small company to one of the nation's largest carriers. Today, Anthem Blue Cross of California is a subsidiary of Anthem Inc. and is an independent licensee of the Blue Cross Blue Shield Association located in Thousand Oaks, California. Anthem Blue Cross covers more Californians than any other carrier in The Golden State with over 8.6 million members. Anthem has been with Covered California since the beginning of the Affordable Care Act in 2014.</p><br>
    <p>Anthem Blue Cross stands out for its key features:</p><br>`,
    listContent: {
      list: [
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>Range of plans:</b> Anthem Blue Cross offers multiple plans, including individual, family, employer group and Medicare Advantage plans.</div></div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>Additional benefits:</b> Dental, vision and prescription drug coverage is included in many plans.</div></div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>Customer support:</b> Anthem Blue Cross has robust customer support with online tools to help members manage their health care and find network providers.</div></div>`,
      ],
    },
    textDirection: 'text-left',
    imageUrl: '/assets/img/carriers/anthem-woman-and-doctor.webp'
  },
  extraSection1: {
    visible: true,
    textDirection: 'text-left',
    title: '2024 Medicare Advantage Plans',
    content: `<p>Anthem Blue Cross offers Medicare Advantage Plans as an alternative to traditional Medicare. Medicare Advantage plans, also known as Medicare Part C, combine medical and hospital insurance into one plan. Anthem Blue Cross PPO insurance plans add extra benefits like vision, dental, over-the- counter health items and prescription drug coverage. They also include the <a class="underline" href="${getBaseUrl('/affordable-care-act/essential-health-benefits')}">10 essential health benefits</a> the law requires. Changes for 2024 include:</p><br>`,
    listContent: {
      list: [
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>Behavioral health care:</b> Medicare Advantage has better coverage for mental health care, including psychiatrists, clinical psychologists and inpatient psychiatric facilities.</div></div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>On-time health care:</b> New changes to Medicare Advantage will help members bypass delays and coverage denial in medically necessary cases.</div></div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div><div class="flex-1"><b>More health equity:</b> Medicare Advantage plans include digital health education to simplify telehealth services, reducing the gap between members and health care.</div></div>`,
      ],
    },
    bgColor: 'bg-slate-100',
    imageSection: {
      title: 'Medicare Advantage plans, also known as Medicare Part C, <b>combine medical and hospital insurance into one plan.</b>',
      titleColor: 'text-white',
      contentWidth: 'w-2/3 md:w-1/2',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/anthem-blue-banner.webp',
    },
  },
  extraSection2: {
    visible: true,
    textDirection: 'text-center',
    title: 'Anthem Blue Cross Blue Shield Health Plans',
    content: `<p>Here are the <a class="underline" href="${getBaseUrl('/covered-california/plans')}">tiers of Anthem Blue Cross coverage</a> you can choose from. Select the plan that best aligns with your needs and budget.</p>`,
  },
  networkInfo: {
    visible: true,
    title: 'Network',
    content: `<p>Anthem's broad provider network includes over <a class="underline" href="https://www.businesswire.com/news/home/20201008005329/en/Anthem-Blue-Cross-Introduces-New-Innovative-Health-Plan-Option-for-Bay-Area-Consumers-and-Employer-Groups" target="_blank">65,000 care providers</a> in California. They have made strides to strengthen physician partnerships. including those serving the rural Amador, Monterey, Shasta, Humboldt, Sutter and Butte counties. It is also working to improve <a class="underline" href="https://www.anthem.com/find-care/" target="_blank">its provider list</a> so that consumers receive accurate information on available providers that would be covered.</p><br>
    <p>With the added benefits of the BlueCard program, EPO members who travel to the U.S. will have access to a wide network of doctors and hospitals.</p>`,
    bgColor: 'bg-sky-50',
  },
  finalSection: {
    visible: true,
    contentDirection: 'right',
    title: 'Telehealth Benefits',
    content: `<p>Integration is the wave of the future. It's become a focal point as Anthem seeks to coordinate doctors and hospitals to provide its members with a seamless experience and coordinated treatment plans.</p><br>
    <p>Anthem offers <a class="underline" href="https://livehealthonline.com/" target="_blank">telehealth through LiveHealth</a>. This online program, which you can use in the privacy of your home, offers real-time face-to-face visits with a doctor of your choice in various specialties. Anthem offers this convenient service 24 hours a day. seven days a week, to all members for a small copay. Anthem members can also access robust online tools that help them:</p><br>`,
    listContent: {
      list: [
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Select a doctor</div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Estimate the cost of a procedure</div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Review billing information</div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Submit claims</div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Access customer support</div>`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>View video tutorials</div`,
        `<div class="flex flex-row"><div class="mt-1 mr-2 rounded-full bg-yellow-500 w-3 h-3"></div>Take advantage of member discounts through participating gyms</div>`,
      ],
    },
    imageUrl: '/assets/img/carriers/anthem-video-consultation.webp',
    textDirection: 'text-left',
  },
  extraSection3: {
    visible: true,
    textDirection: 'text-center',
    bgColor: 'bg-slate-100',
    title: 'Get a Quote for Anthem Blue Cross Providers in California',
    content: `<p>At Health for California, we know that finding the right health insurance provider can be daunting. You want to pick the package that works best for you and your entire family. And with so many options and packages on the market, you may wonder where to begin. Health for California's mission is to make this process easier for every Californian. We want you to feel confident about your decision, so we structure our plans for easy comparisons to help you find the one that meets your needs.</p><br>
    <p>We do this by breaking our health insurance plans down into easy-to-understand tiers. Whether you're a single individual wanting an affordable rate or you're someone who wants maximum coverage for the whole family, there's a package that's perfect for you.</p><br>
    <p>Take the first step toward investing in your health by requesting an Anthem Blue Cross <a class="underline" href="${getQuoteUrl()}">health insurance quote online</a> from Health for California.</p>`,
    imageSection: {
      title: 'Get a Quote for Anthem Blue Cross Providers in California',
      content: `Take the first step toward investing in your health by requesting an Anthem Blue Cross health insurance quote online from Health for California`,
      contentWidth: 'w-2/3 md:w-1/2',
      contentColor: 'text-slate-700',
      justifyContent: 'items-start',
      textDirection: 'text-left',
      imageUrl: '/assets/img/carriers/anthem-final-banner.webp',
      redirectButtonText: 'GET A FREE QUOTE',
      redirectButtonUrl: getQuoteUrl(),
    },
  },
};
